<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">受灾记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="FSSJ"
                    label="发生时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.FSSJ"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="SZLX"
                    label="灾害类型"
                    class="from-item-block"
                >
                    <el-select
                        v-model="zhlxValue"
                        @change="changezhlx"
                        multiple
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(da, index) in zhlxList"
                            :key="index"
                            :label="da.LBMC"
                            :value="da.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item prop="ZHMC" label="灾害名称" class="from-item-block">
                    <el-select
                        v-model="zhmcValue" 
                        multiple 
                        :disabled="!isEdit"
                        @change="changezhmc">
                        <el-option-group
                        v-for="group in zhmcList"
                        :key="group.ID"
                        :label="group.LBMC">
                            <el-option
                                v-for="item in group.CHILDREN"
                                :key="item.ID"
                                :label="group.LBMC+'-'+item.LBMC"
                                :value="item.ID">
                                <span :title="item.LBMC">{{item.LBMC}}</span>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item> -->
                <el-form-item
                    prop="ID"
                    label="自然环境监测记录编号"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.ZRHJJCJLBH"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in ZRHJJCXMNRList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.ZRHJJCXMBH"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="WWZSCD"
                    label="文物灾损程度"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.WWZSCD"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in WwzscdList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="SZQKMS"
                    label="受灾情况描述"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SZQKMS"
                        resize="none"
                        type="textarea"
                        placeholder="遗产自身和遗产地人员、资产等受到的损失情况描述"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="ZQCQDFFCS"
                    label="灾前采取的防范措施"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.ZQCQDFFCS"
                        resize="none"
                        type="textarea"
                        placeholder="在本次灾害发生前采取的防范措施及效果"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="JZJFTRZE"
                    label="救灾经费投入总额(元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JZJFTRZE"
                        @input="toNumber($event, 'JZJFTRZE')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "szjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "804",
                dataDic: {
                    glycbtid: this.heritageId,
                    FSSJ: "",
                    SZLX: "",
                    ZHMC: "",
                    ZRHJJCJLBH: "",
                    WWZSCD: "",
                    SZQKMS: "",
                    ZQCQDFFCS: "",
                    JZJFTRZE: "",
                    JCDXID: null,
                },
            },
            rules: {
                FSSJ: [
                    {
                        required: true,
                        message: "请选择发生时间",
                        trigger: "change",
                    },
                ],
            },
            WwzscdList: [], // 文物灾损程度
            zhlxList: [], // 获得 灾害类型 列表
            // zhmcList: [], // 获得 灾害名称 列表
            ZRHJJCXMNRList: [], // 自然环境监测记录编号 列表
            zhlxValue: [],
            // zhmcValue: [],
        };
    },
    mounted() {
        this.GetEnum();
        this.getZRHJJCXMNRList();
        this.GetZHLXList();
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "GetGLXList",
            "GetZHLXListTree",
        ]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "804",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "WWZSCD") {
                    this.WwzscdList = v.dataItems;
                }
            });
        },
        // 获得自然环境项目列表
        async getZRHJJCXMNRList() {
            let res = await this.GetGLXList({
                itemcode: "801",
                lm: "jcsj",
            });
            this.ZRHJJCXMNRList = res.ResultValue || [];
        },
        // 切换灾害类型
        changezhlx() {
            // this.zhmcList = [];
            // this.zhmcValue = [];
            this.formdata.dataDic.SZLX = this.zhlxValue.join(",");
            // this.zhlxValue.forEach(v => {
            //     this.zhlxList.forEach(item => {
            //         if (v == item.ID) {
            //             this.zhmcList.push(item);
            //         }
            //     })
            // })
        },
        // changezhmc() {
        //     this.formdata.dataDic.ZHMC = this.zhmcValue.join(',');
        // },
        async GetZHLXList() {
            let res = await this.GetZHLXListTree();
            this.zhlxList = res.ResultValue || [];
        },
        GetDataNext() {
            // if (this.formdata.dataDic.ZHMC) {
            //     this.zhmcValue = this.formdata.dataDic.ZHMC.split(',');
            // }
            if (this.formdata.dataDic.SZLX) {
                this.zhlxValue = this.formdata.dataDic.SZLX.split(",");
                // this.zhmcList = [];
                // this.zhlxValue.forEach(v => {
                //     this.zhlxList.forEach(item => {
                //         if (v == item.ID) {
                //             this.zhmcList.push(item);
                //         }
                //     })
                // })
            }
        },
    },
};
</script>

<style></style>
